// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";
@import "example";

// If you want to add custom CSS you can put it here.
@import "custom";

.card-title-clickable {
    cursor: pointer;
    transition: box-shadow 0.3s ease-in-out;
  }
  
  .card-title-clickable:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .card-body-expanded {
    height: 400px; /* Adjust the height as needed */
    padding: 20px; /* Adjust the padding to make the content area bigger */
  }

  .card-title-center {
    text-align: center;
    position: center;
  }
  
  .text-bottom-left {
    position: absolute;
    bottom: 10px;
    left: 10px;
    margin: 0;
  }

  .application-bottom-left {
    position: absolute;
    bottom: 10px;
    left: 10px;
    margin: 20px;
  }
  .web {
    color: #00CFFF; /* Match with the cyan color in the pie chart */
  }
  
  .lifestyle {
    color: #57B96A; /* Match with the green color in the pie chart */
  }
  
  .productivity {
    color: #FF6F61; /* Match with the orange color in the pie chart */
  }
  
  .other {
    color: #FF4444; /* Match with the red color in the pie chart */
  }
